import { Component, Mixins } from 'vue-property-decorator';
import { CanalCollecte } from '@/models/CanalCollecte.model';
import template from './CreerNegociateur.Template.vue';
import ApiAutocompleteHelper from '@/services/ApiAutocompleteHelper';
import CeeAutocomplete from '@/components/CeeAutocomplete.vue';
import { SousCanal } from '@/models/SousCanal.model';
import { ApiService } from '@/services/base/ApiService';
import { Negociateur } from '@/models';
import CreerNegociateurValidator from './CreerNegociateur.Validator';

/**
 * Classe TS associée à la vue de création des négociateurs.
 */
@Component({
    ...template,
    name: 'CreerNegociateur',
    components: {
        CeeAutocomplete,
    },
})
export default class CreerNegociateur extends Mixins(CreerNegociateurValidator) {
    public $refs!: {
        form: HTMLFormElement,
    };

    private loading: boolean;
    private newNegociteur: Negociateur;

    constructor() {
        super();     
        this.loading = false;
        this.newNegociteur = new Negociateur();
    }

    /**
    * Promesse pour l'autocomplete des canaux de collecte.
    * @param nom Critère de recherche.
    */
    public canalCollectePromise(recherche: string): Promise<CanalCollecte[]> {
        return ApiAutocompleteHelper.getAutocompletePromise<CanalCollecte>(recherche, `/canalCollecte/obtenirTous`);
    }


    /**
   * Promesse pour l'autocomplete des sous canaux.
   * @param nom Critère de recherche.
   */
    public sousCanalPromise(recherche: string): Promise<SousCanal[]> {
        return ApiAutocompleteHelper.getAutocompletePromise<SousCanal>(recherche, `/sousCanal/obtenirTous`);
    }
    

    // Ajout d'un négociateur sur une soumission.
    public submit() {
        if (this.$refs.form.validate()) {
            this.loading = true;
            const apiAjout = new ApiService<Negociateur>('negociateur/creer');

            apiAjout.post(this.newNegociteur).then((result: any) => {
                if (result) { (this.$router as any).push({ name: 'gestion-negociateurs' }); }
            }).finally(() => this.loading = false);
        }
    }
}
