import { Component, Mixins } from 'vue-property-decorator';
import { ValidationMixin } from '@/shared/mixins/ValidationMixin';

@Component
export default class CreerNegociateurValidator extends Mixins(ValidationMixin) {
    /**
     * Règles de validation pour l'email.
     *
     * @private
     * @memberof CreerNegociateurValidator
     */
    private emailRules = [        
        (v: string | any) => !v || this.isEmail(v) || 'Le mail de confirmation n\'est pas valide',
    ];

    /**
     * Vérifie si le champ Nom est bien renseigné.
     *
     * @memberof CreerNegociateurValidator
     */
    private nomRules = [
        (v: string | any) => this.required(v) || 'Le nom est obligatoire',
    ];

    /**
     * Vérifie si le champ Prénom est bien renseigné.
     *
     * @memberof CreerNegociateurValidator
     */
    private prenomRules = [
        (v: string | any) => this.required(v) || 'Le prénom est obligatoire',
    ];   
}



